import React from 'react';

import InscriptionForm from '../InscriptionForm/InscriptionForm';

const InscriptionGuatemala: React.FC = () => {
  return (
    <section className="py-layout-5 bg-light-gray-1-5">
      <div className="container">
        <InscriptionForm formURL="https://form.jotform.com/222225257197659"/>
      </div>
    </section>
  )
}

export default InscriptionGuatemala;
